.App {
  height: 90vh;
  text-align: center;
  padding-top: 10px;
}
/** Header **/
#Header
{
  height: 100%;
  max-height: 12vh;
  width: 94vw;
  border: 1px solid  black;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  background-color: white;
  padding: 5px;
}

.PlayerCard {
  width : 100%;
  height: 100%;
  display: flex;
  grid-template-columns: auto auto auto;
  flex-wrap: wrap;
  justify-content: left;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px;
}

.GridItem_img
{
  display: flex;
  height: 100%;
  text-align: left;
}

.GridItem_info
{
  padding-left: 10px;
  height: 100%;
  text-align: left;
  justify-content: center;
  align-content: center;
  display:grid;
}

/** Content **/

#Content
{
  width: 94vw;
  height: 68vh;
  overflow-y: auto;
  max-height: 68vh;
  padding-top: 10px;
  padding-left: 10px;
  padding-right : 10px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color: white;
}

.NavScreen {
  padding: 2vh 2vw;
  z-index: 4;
}

.NavScreen .BottomSpacer {
  margin-top: 2vh;
  width: 96vw;
}

.LoginDiv
{
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid  black;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
  display: flex;
  align-items: center;
}
.Form {
  width: 100%;
}

.ScreenProfile
{
  height: 100%;
  padding: 20px;
  padding-top: 0px;
  display:grid;
}
/** NavBar **/

#NavBar
{
  width: 94vw;
  height: 10vh;
  max-height: 10vh;
  justify-content: center;
  display:flex;
  bottom: 0;
  position: relative;
  border: 1px solid  black;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  background-color: white;
}

#MenuNavbar
{
  border-radius: 0px 0px 10px 10px;
  border: none;
  box-shadow: none;

}

#ButtonNavBarRight
{
  width: 30%;
  height: 80%;
  align-self: flex-end;
  border-top: 1px solid grey;
  border-radius: 5px 5px 5px 0px;
  background-color: #0088ce;
  color:white;
  padding:0px;
  padding-top : 1%;
  padding-bottom: 1%;
}

#ButtonNavBarRight.active
{
  background-color: rgb(0, 17 ,43);
}

#ButtonNavBarLeft
{
  width: 30%;
  height: 80%;
  align-self: flex-end;
  border-top: 1px solid grey;
  border-radius: 5px 5px 0px 5px;
  background-color: #0088ce;
  color:white;
  padding:0px;
  padding-top : 1%;
  padding-bottom: 1%;
}

#ButtonNavBarLeft.active
{
  background-color: rgb(0, 17 ,43);
}

#BigButtonNavBar
{
  width: 40%;
  height: 100%;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  border-bottom: none;
  border-radius: 15px 15px 0px 0px;
  background-color: #0088ce;
  color:white;
  padding:0px;
  padding-top : 1%;
  padding-bottom: 1%;
}

#BigButtonNavBar.active
{
  background-color: rgb(0, 17 ,43);
}

/** Utils img **/
#ImgAutoFit
{
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 2%;
}

#ImgNavBar
{
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 65%;
}

#BigImgNavBar
{
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 75%;
}

.img_small
{
  height: auto;
  width: auto;
  max-width: 40px;
  max-height: 40px;
}

.img_little
{
  height: auto;
  width: auto;
  max-width: 60px;
  max-height: 60px;
}

.img_medium
{
  height: auto;
  width: auto;
  max-width: 80px;
  max-height: 80px;
}

.img_big
{
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 100px;
}

#infoImg
{
  position: absolute;
  bottom: 2%;
  right: 5%;
  width: 20px;
  height: 20px;
}

#tdImg
{
  position: relative;
  top: 0;
  left: 0;
}
/** Components HTML **/

h1 {
  margin: 2vh 2vw;
  font-size: 16px;
  
}

h2 {
  color:blue;
}

#Uppercase
{
  text-transform: uppercase;
}

select, input, button {
  border-radius: 7px;
  padding: 6px;
  font-size: 16px;
}

select {
  display: block;
  border-radius: 5px;
  padding: 6px;
  font-size: 16px;
  margin: auto;
  width: 55%;
}

input {
  display: block;
  border: none;
  background-color: rgb(0,0,0,0.25);
  width: 50%;
  margin: auto;
}

button {
  margin: 4vw;
  border-color: rgb(0,0,0,0.25);
  color: #fff;
  background-color: #0088ce;
}

button:disabled {
  margin: 4vw;
  border-color: rgb(0,0,0,0.25);
  color: #fff;
  background-color: #9fddfc;
}

#inputBtn
{
  border-color: rgb(0,0,0,0.25);
  color: #fff;
  background-color: #0088ce;
  width: 50%;
  text-align: center;
  padding: 6px;
}

#inputBtn:disabled
{
  background-color: #8ac1dd;
}

#button {
  border-color: rgb(0,0,0,0.25);
  color: #fff;
  background-color: #0088ce;
  width: 100%;
  text-align: center;
  padding: 6px;
  opacity: 1 !important;
}

#button.disabled {
  border-color: rgb(0,0,0,0.25);
  color: #fff;
  background-color: #02557e;
  width: 100%;
  text-align: center;
  padding: 6px;
  opacity: 1 !important;
}


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgb(0,0,0,0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Overlay > div {
  background-color: white;
  border-radius: 5px;
  border-color: lightgray;
  padding: 6px;
  width: 80%;
}

/**MATERIAL CSS**/
.materialGridList {
  height: 15vh;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 15px;
}

.materialGridList img {
  border: 2px solid transparent;
  border-radius: 10px;
  flex: 0 0 auto;
  height: 100%;
}

.DetailScore {
  display: inline-flex;
  justify-content: center;
}

#DetailScoreGrid
{
  margin: auto;
  width: 100%;
}

.ScoreBar
{
  display: inline-flex;
  width: 70%;
}

.Leaderboard {
  overflow-y: scroll;
  height:52vh;
}

.Leaderboard table, th{
  table-layout: fixed ;
  border-collapse: collapse;
  width:98%;
}

.Leaderboard tr{
  height: 65px;
  max-height: 65px;
  border-color: rgb(116, 118, 120);
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

#LeaderboardDetail td
{
  padding-top:4%;
}

.user_score{
  background-color: #ccc;
}

.Leaderboard td img{
  height: auto;
  width: auto;
  max-height: 50px;
  max-width: 50px;
}

.popupTeam {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 8;
}

.popupTeam_inner {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 5%;
  bottom: 5%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.popupTeam_Leaderboard {
  overflow-y: auto;
  padding: 10px;
  max-height:50vh;
}

.chevron
{
  list-style-type: none;
}

#ModalAction
{
  text-align: center;
}

#ModalHeader
{
  text-align: center;
}

#TeamDetailList
{
  text-align: center;
}

#TeamDetailList table, th{
  table-layout: fixed ;
  border-collapse: collapse;
  width:100%;
}

#TeamDetailList tr{
  height: 50px;
  border-style: solid;
  border-color: rgb(116, 118, 120);
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
}

#TeamDetailList td img{
  height: 30px;
  width: 30px;
}

.divLeaderboard
{
  padding-top: 10px;
}

li.chevron img {
    max-height: 30px;
    max-width: 30px;
}


.debug
{
  background-color: #d10000;
}
